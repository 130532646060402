import styles from '../orders/create-order/steps/shipping/components/ship-form/ShipForm.module.scss';
import { FiPlus } from 'react-icons/fi';
import React from 'react';
import { validatorService } from '../../../features/validator/validator.service';
import { ShipmentLabelValidateRequest } from '../../../features/validator/shipment-label-validate/shipment-label-validate.request';

const ShipLabelValidatorTestScreen = () => {
	const [message, setMessage] = React.useState<string | null>(null);

	const validate = async (file: File) => {
		try {
			if (!file) return;

			const response = await validatorService.shipmentLabelValidate(new ShipmentLabelValidateRequest(file));

			if (!response) throw '';

			setMessage(response.reasons[0]);
		} catch (error) {
			console.error(error);
		} finally {
		}
	};

	return (
		<section className="container-body p-3 z-1">
			<label htmlFor="printFile" className={styles.printFileWrapper}>
				<input
					type="file"
					value={''}
					onChange={async (e) => {
						if (!e.target.files) return;

						const files = e.target.files as any as File[];

						await validate(files[0]);
					}}
					multiple
					accept=".pdf"
					name="printFile"
					id="printFile"
					className="absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer"
				/>

				<div role="button" tabIndex={0} className={styles.printFileBtn}>
					<FiPlus size="1.808rem" />
					<span>Ship Label File</span>
				</div>

				<div className={styles.printFileDivider} />

				<div className={styles.printFileInfo}>
					<span className={styles.printTitle}>Drag and Drop "Ship Labels" Here</span>
					<span className={styles.printAccepts}>You can upload: .pdf, Files</span>
				</div>
			</label>
			<h5>{message}</h5>
		</section>
	);
};

export default ShipLabelValidatorTestScreen;
