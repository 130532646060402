import { ConfirmSideWithDesign, SideWithDesignDialog } from 'components/_common/modals/side-with-design/SideWithDesignDialog';
import { CreateManuelOrderItemRequestDto, CreateManuelOrderItemSideRequestDto, DeliveryTypes, prefixOptions } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { CoreProductSynchronizeDto } from 'features/products/_common/get-core-product-for-synchronize/get-core-product-for-synchronize.response';
import { CreateReorderRequest } from 'features/reorders/_common/create-reorder/create-reorder.request';
import { EditableOrderItem } from 'features/reorders/_common/create-reorder/create-reorder.response';
import { reorderService } from 'features/reorders/_common/reorder.service';
import { CreateReorderItemItemDto, CreateReorderItemRequest } from 'features/reorders/derived-features/reorder-items/create-reorder-item/create-reorder-item.request';
import { reorderItemService } from 'features/reorders/derived-features/reorder-items/reorder-item.service';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import currencyHelper from 'helpers/curreny.helper';
import htmlToImageHelper from 'helpers/html-to-image.helper';
import stringHelper from 'helpers/string.helper';
import toastHelper from 'helpers/toast.helper';
import GoBackButton from 'helpers/widgets/Buttons/GoBackButton';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import PrDropdown, { PrDropdownType } from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import PrPriceInput from 'helpers/widgets/Printram/Forms/Input/PrPriceInput';
import { PrSwitchToggle } from 'helpers/widgets/Printram/Switch/PrSwitchToggle';
import { OrderItemDto, OrderItemSideDto } from 'models/dtos/order/order';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { IoIosClose, IoMdClose } from 'react-icons/io';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import ProductTable from '../components/ProductTable';
import { OrderDetailInfo } from '../components/order-detail-info/OrderDetailInfo';
import { OrderDetailTitle } from '../components/order-detail-title/OrderDetailTitle';
import styles from './ReOrder.module.scss';
import { ConfirmDialogOptions, confirmDialog } from 'primereact/confirmdialog';
import { acceptedFileExtensions } from 'helpers/constants/accepted-file-extensions';
import { GrAttachment } from 'react-icons/gr';
import PrTextareaInput from 'helpers/widgets/Printram/Forms/Input/PrTextareaInput';
import { CreateReorderCompleteRequest } from 'features/reorders/_common/create-reorder-complete/create-reorder-complete.request';
import { ShippingRateDto } from 'features/orders/derived-features/order-transports/dtos/shipping-rate.dto';
import { GetCreateReOrderDatasRequest } from 'features/reorders/_common/get-create-reorder-datas/get-create-reorder-datas.request';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import { ErrorMessage, Form, Formik } from 'formik';
import { SaveReorderAddressRequest } from 'features/reorders/_common/save-address/save-address.request';
import * as Yup from 'yup';
import AutoCompleteInput from 'helpers/widgets/Printram/Forms/Input/AutoCompleteInput';
import { CountryBaseDto } from 'features/countries/get-countries/get-countries.response';
import { GetShippingRatesForReorderRequest } from 'features/reorders/_common/get-shipping-rates/get-shipping-rates.request';
import { ReOrderDetail } from 'features/reorders/_common/get-create-reorder-datas/get-create-reorder-datas.response';
import { Link } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import { FiPlus } from 'react-icons/fi';
import { SelectPrintramProductDialogNew } from 'components/_common/modals/select-printram-product-new/SelectPrintramProductDialogNew';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import Cookies from 'js-cookie';
import StartTutorialTourButton from 'helpers/widgets/Tutorial/StartTutorialTourButton';
import AddressAutofill from 'helpers/widgets/Order/Address/AddressAutofill';
import StatesAndCountries from '../../../../assets/jsons/states-and-countries/states-and-countries.json';
import { State, StateAndCountry } from 'assets/jsons/states-and-countries/states.type';

export type IterableItemNew = CreateReorderItemItemDto & {
	confirmSideWithDesign?: ConfirmSideWithDesign;
};

export type IterableItem = {
	old: EditableOrderItem;
	new: IterableItemNew;
};

export type ReOrderItemDto = OrderItemDto & {
	isEdited?: boolean;
	reasonOptions: PrDropdownType[];
	selectedReasonOptions: PrDropdownType[];
};

export type ReOrderDto = any;

export const ReOrder = () => {
	const user = useAppSelector((_state) => _state.auth.data?.user);
	const { orderId } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const [step, setStep] = useState(1);
	const [loading, setLoading] = useState({ isActive: false, text: 'Loading' });
	const [selectedProductVisible, setSelectedProductVisible] = useState(false);
	const [sideWithDesignDialogVisible, setSideWithDesignDialogVisible] = useState(false);
	const [order, setOrder] = useState<ReOrderDto | undefined>();
	const [selectedItem, setSelectedItem] = useState<ReOrderItemDto | undefined>(undefined);
	const [selectedOrderItemIds, setSelectedOrderItemIds] = useState<string[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<CoreProductSynchronizeDto | undefined>(undefined);
	const [reorderItemsRequest, setReorderItemsRequest] = useState<CreateReorderItemRequest>(new CreateReorderItemRequest({}));
	const [reorderSaveAddressRequest, setReorderSaveAddressRequest] = useState<SaveReorderAddressRequest>(new SaveReorderAddressRequest({}));
	const [reorderCompleteRequest, setReorderCompleteRequest] = useState<CreateReorderCompleteRequest>(new CreateReorderCompleteRequest({}));
	const [reorderDetail, setReorderDetail] = useState<ReOrderDetail>();
	const [editableItems, setEditableItems] = useState<IterableItem[]>([]);
	const [shippingRates, setShippingRates] = useState<ShippingRateDto[]>([]);
	const [selectedConfirmSideWithDesign, setSelectedConfirmSideWithDesign] = useState<ConfirmSideWithDesign | undefined>(undefined);
	const [countries, setCountries] = useState<CountryBaseDto[]>([]);
	const [orderDeliveryType, setOrderDeliveryType] = useState<DeliveryTypes>(1);
	const [newOrderId, setNewOrderId] = useState<string>('');
	const [selectedAttachmentFile, setSelectedAttachmentFile] = useState<File | undefined>(undefined);
	const [stateOptions, setStateOptions] = useState<PrDropdownType[] | undefined>();
	const [selectedCountryIso, setSelectedCountryIso] = useState<string | undefined>();
	const [tutorialStepsEnabled, setTutorialStepsEnabled] = useState<boolean>(false);
	const [tutorialSteps2Enabled, setTutorialSteps2Enabled] = useState<boolean>(false);
	const [tutorialSteps3Enabled, setTutorialSteps3Enabled] = useState<boolean>(false);
	const [tutorialSteps4Enabled, setTutorialSteps4Enabled] = useState<boolean>(false);
	const [tutorialSteps5Enabled, setTutorialSteps5Enabled] = useState<boolean>(false);

	//#Tutorial Steps region
	const steps1 = [
		{
			element: '.tutorial-selector1',
			intro: 'Within the order, select the items that have issues and need to be reproduced.'
		}
	];

	const steps2 = [
		{
			element: '.tutorial-selector2',
			intro: 'If you want to make changes to the items you wish to reproduce, such as changing the product, color, size, or design, press "Edit" to make the necessary changes.'
		},
		{
			element: '.tutorial-selector3',
			intro: "Select the reasons why you want the items to be reproduced. You can choose multiple reasons. The selected reasons will be evaluated by a Printram representative. If the issue is found to be Printram's fault, the product will be reproduced free of charge."
		}
	];

	const steps3 = [
		{
			element: '.tutorial-selector4',
			intro: 'You can view the item you reported as faulty in this section.'
		},
		{
			element: '.tutorial-selector5',
			intro: 'You can view the item you want to be reproduced in this section.'
		}
	];

	const steps4 = [
		{
			element: '.tutorial-selector6',
			intro: 'If you want to change the address, you can do so from this section.'
		}
	];

	const steps5 = [
		{
			element: '.tutorial-selector7',
			intro: 'For the items you want to reproduce, you can upload supporting images such as screenshots, product photos, and conversations with the customer in this section.'
		},
		{
			element: '.tutorial-selector8',
			intro: 'Please describe the situation or issue you have experienced with the product or order.'
		},
		{
			element: '.tutorial-selector9',
			intro: 'If your previous order was shipped using a method other than express shipping and you want to use a different shipping method, check the box and select your preferred shipping option. If the issue is found to be your fault after evaluation by the Printram expert team, the cost of the selected shipping method may be charged to you.'
		}
	];
	//#end tutorial steps

	const setLoadingState = (loadingProps: Partial<{ isActive: boolean; text: string }>) => {
		const loadingState = { ...loading };

		loadingState.isActive = typeof loadingProps.isActive === 'undefined' ? loadingState.isActive : loadingProps.isActive;
		loadingState.text = typeof loadingProps.text === 'undefined' ? loadingState.text : loadingProps.text;

		setLoading(loadingState);
	};

	const prefixHandler = (phone: string) => (phone ? (phone.startsWith('+90') ? { label: '+90', value: '+90' } : { label: '+1', value: '+1' }) : { label: '+1', value: '+1' });

	function onKeyDown(keyEvent: any) {
		if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
			keyEvent.preventDefault();
		}
	}

	const getReorderDetail = async () => {
		try {
			if (!orderId) throw '';

			setLoadingState({ isActive: true, text: 'Loading Product' });

			const request = new GetCreateReOrderDatasRequest(orderId);

			const response = await reorderService.getCreateReorderDatas(request);

			if (!response.isSuccess || !response.data) throw '';

			const tempOrder = {
				...response.data.oldOrder,
				items: response.data.oldOrder.items.map((_item: ReOrderItemDto) => {
					_item.reasonOptions = response.data?.reorderItemReasons.map((_reason) => ({ label: _reason.readableName, value: _reason.id })).sort((a, b) => a.value - b.value) || [];
					_item.selectedReasonOptions = [];
					_item.isEdited = undefined;
					return _item;
				})
			};

			const items: IterableItem[] = tempOrder.items.map((_item: ReOrderItemDto) => ({ old: _item, new: { oldItemId: _item.id } })) as any[];

			if (response.data.progressStatus.step === 1 && response.data.reorderId) {
				const filteredItems = tempOrder.items.filter((_item: ReOrderItemDto) => response.data?.reorderDetail.selectedItemIds.includes(_item.id));
				const reorderItems = new CreateReorderItemRequest({
					reorderId: response.data.reorderId,
					items: filteredItems.map((_item: ReOrderItemDto) => new CreateReorderItemItemDto({ oldItemId: _item.id }))
				});

				setReorderItemsRequest(reorderItems);
			}

			if (response.data.reorderId) {
				setReorderCompleteRequest(
					new CreateReorderCompleteRequest({
						...reorderCompleteRequest,
						reorderId: response.data.reorderId
					})
				);
			}

			if (response?.data?.reorderDetail?.selectedItemIds?.length > 0) {
				const filteredItems = items.filter((_item) => response.data?.reorderDetail.selectedItemIds.includes(_item.old.id));
				setEditableItems(filteredItems);
			} else setEditableItems(items);

			if (response?.data?.reorderDetail?.newOrderId) setNewOrderId(response?.data?.reorderDetail?.newOrderId);

			setCountries(response.data.countries);
			setOrder(tempOrder);
			const selectedIso = tempOrder.orderAddress?.countryIso.length === 2 ? tempOrder.orderAddress?.countryIso : response.data.countries.find((_country) => _country.iso3 === tempOrder.orderAddress?.countryIso)?.iso2;
			setSelectedCountryIso(selectedIso);
			setReorderDetail(response.data?.reorderDetail);
			setOrderDeliveryType(response.data?.reorderDetail?.deliveryType || response.data.oldOrder.deliveryType);
			setReorderSaveAddressRequest(new SaveReorderAddressRequest({ ...reorderSaveAddressRequest, address: response.data.oldOrder.orderAddress || null, reorderId: response.data.reorderId }));
			setStep(++response.data.progressStatus.step || 0);
		} catch (error) {
			setOrder(undefined);
			setCountries([]);
		} finally {
			setLoadingState({ isActive: false });
		}
	};

	const calculateSidePrice = (item: OrderItemDto) => {
		let price = item.price.pricePerUnit;

		for (const side of item.sides) price += side.printPrice.pricePerUnit;

		return price / 100;
	};

	const calculateSidePriceForNew = (item: IterableItem) => {
		const sides: OrderItemSideDto[] = [];

		for (const side of item.new.item?.sides || []) {
			const oldSide = item.old.sides.find((_side) => _side.productPrintSideId === side.productPrintSideId);
			if (!!oldSide) sides.push(oldSide);
		}

		let price = item.old.price.pricePerUnit;

		for (const side of sides) price += side.printPrice.pricePerUnit;

		return price / 100;
	};

	const confirmStepTwo = async () => {
		try {
			setLoadingState({ isActive: true, text: 'File Processing:' });

			if (editableItems.findIndex((_item) => _item.old.selectedReasonOptions.length < 1) > -1) {
				throw 'To proceed, selecting a reason for each product is necessary.';
			}

			const newRequest = { ...reorderItemsRequest };

			for (const item of newRequest.items) {
				const findedItem = editableItems.find((_item) => _item.old.id === item.oldItemId);
				if (!findedItem?.old.reasonOptions) continue;

				if (!!findedItem.new) item.item = findedItem.new.item;

				item.reasonTypes = findedItem.old.selectedReasonOptions.map((_option) => Number(_option.value));
			}

			const response = await reorderItemService.createItems(new CreateReorderItemRequest(newRequest), (event) => {
				const percentage = Math.round((event.loaded * 100) / event.total);
				if (percentage !== 100) setLoadingState({ isActive: true, text: `File Processing: ${percentage}%` });
				else setLoadingState({ isActive: true, text: `Processing` });
			});

			if (!response.isSuccess) throw !!response.message && response.message.length > 0 ? response.message : null;

			setReorderCompleteRequest(
				new CreateReorderCompleteRequest({
					reorderId: newRequest.reorderId
				})
			);

			setStep(3);
			Cookies.get('re-order') !== 'true' && setTutorialSteps4Enabled(true);
		} catch (error) {
			if (typeof error === 'string') toastHelper.warning(error);
		} finally {
			setLoadingState({ isActive: false });
		}
	};

	const getShippingRates = async () => {
		if (!newOrderId) return;

		try {
			setLoadingState({ text: 'Loading shipping rates', isActive: true });

			const request = new GetShippingRatesForReorderRequest(newOrderId);

			const response = await reorderService.getShippingRates(request);

			if (!response.isSuccess || !response.data) throw '';

			const formattedShippingRates = response.data.shippingRates.map((_rate) => _rate.shippingRate);

			setShippingRates(formattedShippingRates);
		} catch (error) {
			setShippingRates([]);
		} finally {
			setLoadingState({ text: 'Loading', isActive: false });
		}
	};

	const allTutorialsComplete = () => {
		setTutorialSteps5Enabled(false);
		Cookies.set('re-order', 'true');
	};

	const startTutorials = () => {
		console.log('STEP:', step);
		Cookies.remove('re-order');
		setTutorialSteps2Enabled(false);
		setTutorialSteps3Enabled(false);
		setTutorialSteps4Enabled(false);
		setTutorialSteps5Enabled(false);
		setTutorialStepsEnabled(false);

		switch (step) {
			case 1:
				setTutorialStepsEnabled(true);
				break;
			case 2:
				setTutorialSteps2Enabled(true);
				break;
			case 3:
				setTutorialSteps4Enabled(true);
				break;
			case 4:
				setTutorialSteps5Enabled(true);
				break;
		}
	};

	useEffect(() => {
		if (!!orderId) {
			setReorderCompleteRequest(
				new CreateReorderCompleteRequest({
					...reorderCompleteRequest,
					reorderId: orderId
				})
			);
			getReorderDetail();
		}
	}, [orderId]);

	useEffect(() => {
		if (!orderDeliveryType) return;

		setReorderSaveAddressRequest(new SaveReorderAddressRequest({ ...reorderSaveAddressRequest, deliveryType: orderDeliveryType }));
	}, [orderDeliveryType]);

	useEffect(() => {
		if (reorderCompleteRequest.isShippingServiceRequired && shippingRates.length < 1) {
			getShippingRates();
		} else {
			setReorderCompleteRequest(new CreateReorderCompleteRequest({ ...reorderCompleteRequest, requestShippingRate: null }));
			setShippingRates([]);
		}
	}, [reorderCompleteRequest.isShippingServiceRequired]);

	useEffect(() => {
		if (!order || Cookies.get('re-order') === 'true') return;

		setTutorialStepsEnabled(true);
	}, [order]);

	useEffect(() => {
		const statesAndCountries = structuredClone(StatesAndCountries);

		setStateOptions(statesAndCountries.find((_states: StateAndCountry) => _states.country === selectedCountryIso)?.states.map((_statesOption: State) => ({ label: _statesOption.name, value: _statesOption.abbreviation })));
	}, [selectedCountryIso]);

	return (
		<main className="container relative">
			{/* <Steps enabled={tutorialStepsEnabled} steps={steps1} initialStep={0} onExit={() => setTutorialStepsEnabled(false)} onComplete={() => setTutorialStepsEnabled(false)} options={{ showBullets: false, dontShowAgainCookie: 're-order', disableInteraction: false }} />
			<Steps enabled={tutorialSteps2Enabled} steps={steps2} initialStep={0} onExit={() => setTutorialSteps2Enabled(false)} onComplete={() => setTutorialSteps2Enabled(false)} options={{ showBullets: false, dontShowAgainCookie: 're-order', disableInteraction: false }} />
			<Steps enabled={tutorialSteps3Enabled} steps={steps3} initialStep={0} onExit={() => setTutorialSteps3Enabled(false)} onComplete={() => setTutorialSteps3Enabled(false)} options={{ showBullets: false, dontShowAgainCookie: 're-order', disableInteraction: false }} />
			<Steps enabled={tutorialSteps4Enabled} steps={steps4} initialStep={0} onExit={() => setTutorialSteps4Enabled(false)} onComplete={() => setTutorialSteps4Enabled(false)} options={{ showBullets: false, dontShowAgainCookie: 're-order', disableInteraction: false }} />
			<Steps enabled={tutorialSteps5Enabled} steps={steps5} initialStep={0} onExit={() => allTutorialsComplete()} onComplete={() => allTutorialsComplete()} options={{ showBullets: false, dontShowAgainCookie: 're-order', disableInteraction: false }} /> */}

			<section className="container-header">
				{!!order ? <OrderDetailTitle {...order} /> : null}

				<div className="contianer-header-tools flex flex-row align-items-center">
					{/* <StartTutorialTourButton onClickEvent={startTutorials} /> */}

					<GoBackButton />
				</div>
			</section>

			<OrderDetailInfo order={order} />

			<section className={`container-body ${styles.firstStep} ${styles.stepWrapper}${step === 1 ? ` ${styles.active}` : step > 1 ? ` ${styles.completed}` : step < 1 ? ` ${styles.disabled}` : ''}`}>
				<h4>Create Reorder</h4>
				<hr className="my-1" />

				<h5>
					<span>1</span> {step > 1 ? 'Products selected' : 'Select products'}
				</h5>

				{step === 1 ? (
					<div className="mt-4 mb-2">
						<div className="flex gap-2 align-items-center">
							<PrSwitchToggle
								checked={selectedOrderItemIds.length === order?.items.length}
								onChange={(e) => {
									setSelectedOrderItemIds(e ? order?.items.map((_item: ReOrderItemDto) => _item.id) || [] : []);
								}}
							/>

							<span className="tutorial-selector1">Select all products</span>
						</div>

						<ProductTable orderItems={order?.items} canSelectableItems selectedItemIds={selectedOrderItemIds} setSelectedItemIds={setSelectedOrderItemIds} />

						<div className="flex justify-content-end w-full">
							<PrButton
								text="Next"
								disabled={selectedOrderItemIds.length < 1}
								icon={<FaChevronRight />}
								onClick={async () => {
									try {
										setLoadingState({ isActive: true, text: 'Items creating' });
										const response = await reorderService.create(
											new CreateReorderRequest({
												orderId: orderId,
												selectedItemIds: selectedOrderItemIds
											})
										);

										if (!response.isSuccess || !response.data || !order) throw '';

										const filteredItems = order.items.filter((_item: ReOrderItemDto) => selectedOrderItemIds.includes(_item.id));
										const reorderItems = new CreateReorderItemRequest({
											reorderId: response.data.reorderId,
											items: filteredItems.map((_item: ReOrderItemDto) => new CreateReorderItemItemDto({ oldItemId: _item.id }))
										});

										const iterableItems: IterableItem[] = [];

										for (const item of filteredItems) {
											const reorderItem = reorderItems.items.find((_reorderItem) => _reorderItem.oldItemId === item.id);

											iterableItems.push({ old: item as any, new: reorderItem! });
										}

										if (response.data.reorderId) setReorderSaveAddressRequest(new SaveReorderAddressRequest({ ...reorderSaveAddressRequest, reorderId: response.data.reorderId }));

										setEditableItems(iterableItems);
										setReorderItemsRequest(reorderItems);

										setStep(2);
										Cookies.get('re-order') !== 'true' && setTutorialSteps2Enabled(true);
									} catch (error) {
										setEditableItems([]);
										setReorderItemsRequest(new CreateReorderItemRequest({ reorderId: reorderCompleteRequest.reorderId }));
									} finally {
										setLoadingState({ isActive: false });
									}
								}}
							/>
						</div>
					</div>
				) : null}
			</section>

			<section className={`container-body ${styles.secondStep} ${styles.stepWrapper}${step === 2 ? ` ${styles.active}` : step > 2 ? ` ${styles.completed}` : step < 2 ? ` ${styles.disabled}` : ''}`}>
				<h4>
					<span>2</span> Edit selected products
				</h4>

				{step === 2 && !!editableItems && editableItems.length > 0 ? (
					<div className="mt-4 mb-2" style={{ minHeight: '30rem' }}>
						<table className={styles.table}>
							<thead>
								<tr>
									<th>Product</th>
									<th>Print File</th>
									<th>QTY</th>
									<th>Retail Each</th>
									<th>Total Price</th>
								</tr>
							</thead>

							<tbody>
								{editableItems.map((_item, index) => (
									<React.Fragment key={index}>
										{!!_item?.new?.item ? (
											<tr className={styles.info}>
												<td colSpan={5}>
													<span className={styles.old}>Old</span>
												</td>
											</tr>
										) : null}

										<tr className="tutorial-selector4">
											<td>
												<div className={styles.productInfo}>
													<div className={styles.productImgContainer}>
														<img src={amazonS3Helper.showFile(_item.old.variantMedia, { resize: '100x100' })} alt={_item.old.coreProductName} width={80} />
													</div>

													<div className={styles.productInfoContainer}>
														<h6>{_item.old.coreProductName}</h6>

														<span>
															Model: {_item.old.brandName} {_item.old.modelName}
														</span>

														<span>Size: {_item.old.size.name}</span>

														<span className={styles.productInfoContainerColor}>
															<Tooltip target={`#color-${index}-${_item.old.color.id}`} />
															Color:
															<span id={`color-${index}-${_item.old.color.id}`} data-pr-tooltip={_item.old.color.hexCode} data-pr-position="top" style={{ backgroundColor: _item.old.color.hexCode }} />
															{_item.old.color.name}
														</span>

														{!_item?.new?.item ? (
															<span className="flex gap-3 align-items-center text-primary mt-2">
																<span
																	role="button"
																	className="text-primary cursor-pointer underline tutorial-selector2"
																	tabIndex={0}
																	onClick={() => {
																		setSelectedItem(structuredClone(_item.old));
																		setSelectedProductVisible(true);
																	}}>
																	Edit
																</span>
															</span>
														) : null}
													</div>
												</div>

												{!_item?.new?.item ? (
													<React.Fragment>
														<PrDropdown
															className="w-20rem mt-3 tutorial-selector3"
															placeholder="Select reason"
															disabled={_item.old.reasonOptions?.length < 1}
															options={_item.old.reasonOptions}
															onChange={(event) => {
																const clonedEditableItems: IterableItem[] = structuredClone(editableItems);

																for (const item of clonedEditableItems) {
																	if (item.old.id !== _item.old.id) continue;

																	item.old.selectedReasonOptions = item.old.selectedReasonOptions.concat(event).sort((a, b) => +a.value - +b.value);
																	item.old.reasonOptions = item.old.reasonOptions.filter((_option) => _option.value !== event.value).sort((a, b) => +a.value - +b.value);

																	break;
																}

																setEditableItems(clonedEditableItems);
															}}
														/>

														{_item.old.selectedReasonOptions?.length > 0 ? (
															<div className={styles.reasonWrapper}>
																{_item.old.selectedReasonOptions.map((_option) => (
																	<div className={styles.reasonItem} key={_option.value}>
																		<span className={styles.itemText}>{_option.label}</span>

																		<span
																			className={styles.itemCloseIconWrapper}
																			role="button"
																			tabIndex={0}
																			onClick={() => {
																				const clonedEditableItems: IterableItem[] = structuredClone(editableItems);

																				for (const item of clonedEditableItems) {
																					if (item.old.id !== _item.old.id) continue;

																					item.old.selectedReasonOptions = item.old.selectedReasonOptions.filter((_innerOption) => _innerOption.value !== _option.value).sort((a, b) => +a.value - +b.value);
																					item.old.reasonOptions = item.old.reasonOptions.concat?.(_option).sort((a, b) => +a.value - +b.value);

																					break;
																				}

																				setEditableItems(clonedEditableItems);
																			}}>
																			<IoIosClose size={22} />
																		</span>
																	</div>
																))}
															</div>
														) : null}
													</React.Fragment>
												) : null}
											</td>

											<td>
												<div className={styles.printFileWrapper}>
													{_item.old.sides.map((_side, sideIndex) => (
														<React.Fragment key={sideIndex}>
															<div className={styles.printFileItem}>
																<div className={styles.printFileItemImgContainer}>
																	<img src={amazonS3Helper.showFile(_side.design, { resize: '250x250' })} width={100} alt={`${_side.printSideName} Design`} style={{ filter: 'drop-shadow(0 0 0.4px #AEAEAE)' }} />
																</div>

																<div className={styles.printFileItemInfo}>
																	<span>{_side.printSideName} Side Print File</span>
																</div>
															</div>

															<div className={styles.printFileItem}>
																<div className={styles.printFileItemImgContainer}>
																	<img src={amazonS3Helper.showFile(_side.templateMockup, { resize: '250x250' })} width={100} alt={`${_side.printSideName} Mockup`} />
																</div>

																<div className={styles.printFileItemInfo}>
																	<span>{_side.printSideName} Side Mockup</span>
																</div>
															</div>
														</React.Fragment>
													))}
												</div>
											</td>

											<td>
												<span style={{ lineHeight: '2.5rem', display: 'inline-block', marginRight: '2rem' }}>1</span>
											</td>

											<td>
												<PrPriceInput value={calculateSidePrice(_item.old)} readOnly className="max-w-7rem" style={{ marginTop: '-.3rem' }} />
											</td>

											<td>
												<span className="font-bold" style={{ lineHeight: '2.5rem', display: 'inline-block' }}>
													{currencyHelper.formatPrice(calculateSidePrice(_item.old))}
												</span>
											</td>
										</tr>

										{!!_item?.new?.item ? (
											<React.Fragment>
												<tr className={styles.info}>
													<td colSpan={5}>
														<span className={styles.new}>New</span>
													</td>
												</tr>

												<tr className="tutorial-selector5">
													<td>
														<div className={styles.productInfo}>
															<div className={styles.productImgContainer}>
																<img src={amazonS3Helper.showFile(_item.new.confirmSideWithDesign?.variant.medias[0], { resize: '100x100' })} alt={_item.old.coreProductName} width={80} />
															</div>

															<div className={styles.productInfoContainer}>
																<h6>{_item.new.confirmSideWithDesign?.product?.productName}</h6>

																<span>
																	Model: {_item.new.confirmSideWithDesign?.product.model.name} {_item.new.confirmSideWithDesign?.product.model.brand.name}
																</span>

																<span>Size: {_item.new.confirmSideWithDesign?.variant.size.name}</span>

																<span className={styles.productInfoContainerColor}>
																	<Tooltip target={`#color-${index}-${_item.new.confirmSideWithDesign?.variant.color.id}`} />
																	Color:
																	<span id={`color-${index}-${_item.new.confirmSideWithDesign?.variant.color.id}`} data-pr-tooltip={_item.new.confirmSideWithDesign?.variant.color.hexCode} data-pr-position="top" style={{ backgroundColor: _item.new.confirmSideWithDesign?.variant.color.hexCode }} />
																	{_item.new.confirmSideWithDesign?.variant.color.name}
																</span>

																<span className="flex gap-3 align-items-center text-primary mt-2">
																	<span
																		role="button"
																		className="text-primary cursor-pointer underline"
																		tabIndex={0}
																		onClick={() => {
																			// setSelectedConfirmSideWithDesign(structuredClone(_item.new.confirmSideWithDesign));
																			// setSelectedItem(structuredClone(_item.old));
																			// setSideWithDesignDialogVisible(true);

																			setSelectedItem(structuredClone(_item.old));
																			setSelectedProductVisible(true);
																		}}>
																		Edit
																	</span>

																	<span
																		role="button"
																		className="text-pink-500 cursor-pointer underline"
																		tabIndex={0}
																		onClick={() => {
																			const clonedEditableItems: IterableItem[] = structuredClone(editableItems);

																			for (const item of clonedEditableItems) {
																				if (item.new.item?.uniqueId !== _item.new.item?.uniqueId) continue;

																				item.new.item = null;

																				break;
																			}

																			setEditableItems(clonedEditableItems);
																		}}>
																		<span className="pi pi-fw pi-trash mr-1" />
																		<span>Remove</span>
																	</span>
																</span>
															</div>
														</div>

														<PrDropdown
															className="w-20rem mt-3"
															placeholder="Select reason"
															disabled={_item.old.reasonOptions?.length < 1}
															options={_item.old.reasonOptions}
															onChange={(event) => {
																const clonedEditableItems: IterableItem[] = structuredClone(editableItems);

																for (const item of clonedEditableItems) {
																	if (item.old.id !== _item.old.id) continue;

																	item.old.selectedReasonOptions = item.old.selectedReasonOptions.concat(event).sort((a, b) => +a.value - +b.value);
																	item.old.reasonOptions = item.old.reasonOptions.filter((_option) => _option.value !== event.value).sort((a, b) => +a.value - +b.value);

																	break;
																}

																setEditableItems(clonedEditableItems);
															}}
														/>

														{_item.old.selectedReasonOptions?.length > 0 ? (
															<div className={styles.reasonWrapper}>
																{_item.old.selectedReasonOptions.map((_option) => (
																	<div className={styles.reasonItem} key={_option.value}>
																		<span className={styles.itemText}>{_option.label}</span>

																		<span
																			className={styles.itemCloseIconWrapper}
																			role="button"
																			tabIndex={0}
																			onClick={() => {
																				const clonedEditableItems: IterableItem[] = structuredClone(editableItems);

																				for (const item of clonedEditableItems) {
																					if (item.old.id !== _item.old.id) continue;

																					item.old.selectedReasonOptions = item.old.selectedReasonOptions.filter((_innerOption) => _innerOption.value !== _option.value).sort((a, b) => +a.value - +b.value);
																					item.old.reasonOptions = item.old.reasonOptions.concat?.(_option).sort((a, b) => +a.value - +b.value);

																					break;
																				}

																				setEditableItems(clonedEditableItems);
																			}}>
																			<IoIosClose size={22} />
																		</span>
																	</div>
																))}
															</div>
														) : null}
													</td>

													<td>
														<div className={styles.printFileWrapper}>
															{_item.new.confirmSideWithDesign?.sides.map((_side, sideIndex) => (
																<React.Fragment key={sideIndex}>
																	<div className={styles.printFileItem}>
																		<div className={styles.printFileItemImgContainer}>
																			<img src={URL.createObjectURL(_side.designFile!)} width={100} alt={`${_side.name} Design`} style={{ filter: 'drop-shadow(0 0 0.4px #AEAEAE)' }} />
																		</div>

																		<div className={styles.printFileItemInfo}>
																			<span>{_side.name} Side Print File</span>
																		</div>
																	</div>

																	<div className={styles.printFileItem}>
																		<div className={styles.printFileItemImgContainer}>
																			<img src={_side.mockupBase64} width={100} alt={`${_side.name} Mockup`} />
																		</div>

																		<div className={styles.printFileItemInfo}>
																			<span>{_side.name} Side Mockup</span>
																		</div>
																	</div>
																</React.Fragment>
															))}
														</div>
													</td>

													<td>
														<span style={{ lineHeight: '2.5rem', display: 'inline-block', marginRight: '2rem' }}>1</span>
													</td>

													<td>
														<PrPriceInput value={calculateSidePriceForNew(_item)} readOnly className="max-w-7rem" style={{ marginTop: '-.3rem' }} />
													</td>

													<td>
														<span className="font-bold" style={{ lineHeight: '2.5rem', display: 'inline-block' }}>
															{currencyHelper.formatPrice(calculateSidePriceForNew(_item))}
														</span>
													</td>
												</tr>
											</React.Fragment>
										) : null}
									</React.Fragment>
								))}
							</tbody>
						</table>

						<div className="flex justify-content-end w-full gap-4 align-items-center">
							<PrButton
								text="Next"
								icon={<FaChevronRight />}
								onClick={() => {
									if (editableItems.find((_editableItems) => !_editableItems?.new?.item)) {
										confirmDialog({
											header: 'Hey There,',
											message: 'You have not made any changes to the product(s). Do you want to continue without making changes?',
											rejectLabel: 'No',
											acceptLabel: 'Yes, sure!',
											closable: false,
											style: { maxWidth: '30rem' },
											contentStyle: { padding: '.55rem' },
											accept: confirmStepTwo
										});
									} else {
										confirmStepTwo();
									}
								}}
							/>
						</div>
					</div>
				) : null}
			</section>

			<section className={`container-body ${styles.thirdStep} ${styles.stepWrapper}${step === 3 ? ` ${styles.active}` : step > 3 ? ` ${styles.completed}` : step < 3 ? ` ${styles.disabled}` : ''}`}>
				<h4>
					<span>3</span> Address
				</h4>

				{step === 3 ? (
					<div className="mt-4 mb-2">
						<div className="flex align-items-center gap-1 mt-3">
							<PrSwitchToggle checked={orderDeliveryType === 2 ? true : false} onChange={(e) => setOrderDeliveryType(e ? 2 : 1)} />
							Is Pick up order?
						</div>

						<Formik
							initialValues={
								new SaveReorderAddressRequest({
									address: {
										firstLine: order?.orderAddress?.firstLine || '',
										secondLine: order?.orderAddress?.secondLine || '',
										city: order?.orderAddress?.city || '',
										state: order?.orderAddress?.state || '',
										zip: order?.orderAddress?.zip || '',
										countryIso: order?.orderAddress?.countryIso || '',
										receiverFullName: order?.orderAddress?.receiverFullName || '',
										receiverEmail: order?.orderAddress?.receiverEmail || '',
										formattedAddress: order?.orderAddress?.formattedAddress || '',
										vatNumber: order?.orderAddress?.vatNumber || '',
										receiverPhoneNumber: order?.orderAddress?.receiverPhoneNumber || '',
										phonePrefix: order?.orderAddress?.phonePrefix || prefixHandler(order?.orderAddress?.receiverPhoneNumber) || { label: '+1', value: '+1' }
									},
									reorderId: reorderSaveAddressRequest.reorderId || '',
									deliveryType: reorderSaveAddressRequest.deliveryType || 1
								})
							}
							enableReinitialize
							validationSchema={
								orderDeliveryType !== 2
									? Yup.object().shape({
											address: Yup.object().shape({
												receiverFullName: Yup.string()
													.required('Full Name is required')
													.test('is-full-name', 'Enter your Full Name', (value) => {
														if (!value) return false;

														return value.trim().split(' ').length > 1;
													}),
												receiverEmail: Yup.string().email('Email address must be valid.').required('Email is required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.'),
												countryIso: Yup.string().required('Select a country'),
												state: Yup.string().required('State is required'),
												firstLine: Yup.string().required('Address Line 1 is required'),
												city: Yup.string().required('City is required'),
												zip: Yup.string().required('Zip Code is required')
											})
									  })
									: null
							}
							onSubmit={async (values: SaveReorderAddressRequest) => {
								try {
									setLoadingState({ isActive: true, text: 'Saving' });

									const request = new SaveReorderAddressRequest(JSON.parse(JSON.stringify(values)));

									request.attachments = values.attachments || null;

									if (!values?.address?.receiverPhoneNumber || values?.address?.receiverPhoneNumber.length < 1) request.address = { ...(values.address as any), receiverPhoneNumber: null };
									if (values.address?.receiverPhoneNumber) request.address = structuredClone({ ...values.address, receiverPhoneNumber: values.address.phonePrefix?.value + values.address.receiverPhoneNumber.replaceAll(`${prefixOptions[0].value}`, '').replaceAll(`${prefixOptions[1].value}`, '') });

									if (request.deliveryType === 2) request.address = null;
									if (request.deliveryType === 1) request.attachments = null;

									const response = await reorderService.saveReorderAddress(request);

									if (!response.isSuccess) throw '';

									setStep(4);
									Cookies.get('re-order') !== 'true' && setTutorialSteps5Enabled(true);
									setNewOrderId(response.data?.newOrderId || '');
								} catch (error) {
									if (typeof error === 'string') toastHelper.warning(error);
								} finally {
									setLoadingState({ isActive: false });
								}
							}}>
							{(form) => (
								<Form onKeyDown={onKeyDown}>
									{orderDeliveryType === 1 ? (
										<div className="container-body p-0 flex tutorial-selector6">
											<div className="flex flex-row w-full">
												<div className="flex-1 p-5">
													<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
														Recipient
													</h2>
													<div className="mt-4">
														<PrTextInput
															type="text"
															label="Full Name *"
															style={{ height: '2.375rem' }}
															value={form.values?.address?.receiverFullName}
															onChange={(e) => {
																const formattedName = stringHelper.formatName(e.target.value);

																form.setFieldValue('address.receiverFullName', formattedName);
															}}
														/>

														<ErrorMessage name="address.receiverFullName" component="small" className="text-red font-medium" />
													</div>

													<div className="mt-4">
														<PrTextInput
															type="email"
															label="Email *"
															value={form.values?.address?.receiverEmail || ''}
															style={{ height: '2.375rem' }}
															onChange={(e) => {
																form.setFieldValue('address.receiverEmail', e.target.value || null);
															}}
														/>

														<ErrorMessage name="address.receiverEmail" component="small" className="text-red font-medium" />
													</div>

													<label className="mt-4 block" style={{ fontSize: '19px', fontWeight: '600' }}>
														Phone <span className="pr-input-label-optional">(Optional)</span>
													</label>

													<div className="flex gap-2 align-items-end mt-1">
														<div>
															<PrDropdown
																options={prefixOptions}
																value={form.values?.address?.phonePrefix || undefined}
																className="w-7rem"
																onChange={(e) => {
																	form.setFieldValue('address.phonePrefix', e);
																}}
															/>
														</div>

														<PrTextInput
															name="phone"
															type="tel"
															value={form.values?.address?.receiverPhoneNumber?.replaceAll(`${prefixOptions[0].value}`, '').replaceAll(`${prefixOptions[1].value}`, '') || ''}
															className="w-full"
															style={{ height: '2.375rem' }}
															onChange={(e) => {
																if (e.target.value.length > 10) return;

																if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value)) return;

																form.setFieldValue('address.receiverPhoneNumber', e.target.value);
															}}
														/>
													</div>

													<div className="mt-4">
														<PrTextInput
															label="VAT number"
															required={false}
															value={form.values?.address?.vatNumber || ''}
															style={{ height: '2.375rem' }}
															onChange={(e) => {
																form.setFieldValue('address.vatNumber', e.target.value || '');
															}}
														/>
													</div>
												</div>

												<div className="flex-1 border-left-1 border-400 p-5">
													<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
														Shipping address
													</h2>

													{/* {countries.length > 0 ? (
														<div className="mt-4">
															<AutoCompleteInput
																label="Search Address *"
																value={form.values?.address?.formattedAddress}
																defaultValue={form.values.address && form.values?.address?.formattedAddress?.length > 1 ? form.values.address?.formattedAddress : ''}
																style={{ height: '2.375rem' }}
																onChange={(e) => form.setFieldValue('address.formattedAddress', e.target.value)}
																onPlaceSelectedDto={(e) => {
																	form.setFieldValue('address.formattedAddress', e.formattedAddress);
																	form.setFieldValue('address.state', e.state);
																	form.setFieldValue('address.city', e.city);
																	form.setFieldValue('address.zip', e.zip);
																	form.setFieldValue('address.firstLine', e.firstLine);
																	form.setFieldValue('address.secondLine', e.secondLine);

																	for (const country of countries) {
																		if (country.iso2 !== e.countryIso2) continue;

																		form.setFieldValue('address.countryIso', country.iso3);
																		break;
																	}
																}}
															/>

															<ErrorMessage name="address.formattedAddress" component="small" className="text-red font-medium" />
														</div>
													) : null} */}

													{countries.length > 0 ? (
														<AddressAutofill
															defaultAddress={form.values.address?.formattedAddress || ''}
															onAutofillConfirm={(e) => {
																if (!e) return;
																form.setFieldValue('address.formattedAddress', e.formatted_address || '');
																form.setFieldValue('address.state', e.state_province || '');
																form.setFieldValue('address.city', e.city_locality || '');
																form.setFieldValue('address.zip', e.postal_code || '');
																form.setFieldValue('address.firstLine', e.address_line_1 || '');
																form.setFieldValue('address.secondLine', e.address_line_2 || '');

																for (const country of countries) {
																	if (country.iso3 !== e.country_code && country.iso2 !== e.country_code && country.name.toUpperCase() !== e.country_code) continue;

																	form.setFieldValue('address.countryIso', country.iso3);
																	setSelectedCountryIso(country.iso2);
																	break;
																}
															}}
														/>
													) : null}

													<div className="mt-4 flex gap-4">
														<div className="w-full">
															<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
																Country *
															</label>

															<div>
																<PrDropdown
																	value={countries.map((_country) => ({ label: _country.name, value: _country.iso3 })).find((_country) => _country.value === form.values?.address?.countryIso)}
																	options={countries.map((_country) => ({ label: _country.name, value: _country.iso3 }))}
																	className="w-full"
																	onChange={(e) => {
																		form.setFieldValue('address.countryIso', e.value);
																		setSelectedCountryIso(countries.find((_country) => _country.iso3 === e.value)?.iso2);
																	}}
																/>

																<ErrorMessage name="address.countryIso" component="small" className="text-red font-medium" />
															</div>
														</div>

														<div className="w-full">
															<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
																State *
															</label>

															{stateOptions ? (
																<PrDropdown
																	value={stateOptions.find((_states) => _states.value === form.values.address?.state)}
																	options={stateOptions}
																	className="w-full"
																	onChange={(e) => {
																		form.setFieldValue('address.state', e.value);
																	}}
																/>
															) : (
																<PrTextInput
																	value={form.values?.address?.state || ''}
																	className="w-full"
																	style={{ height: '2.375rem' }}
																	onChange={(e) => {
																		form.setFieldValue('address.state', e.target.value || '');
																	}}
																/>
															)}

															<ErrorMessage name="address.state" component="small" className="text-red font-medium" />
														</div>
													</div>

													<div className="mt-4">
														<PrTextInput
															label="Address Line 1 *"
															value={form.values?.address?.firstLine || ''}
															style={{ height: '2.375rem' }}
															onChange={(e) => {
																form.setFieldValue('address.firstLine', e.target.value || '');
															}}
														/>

														<ErrorMessage name="address.firstLine" component="small" className="text-red font-medium" />
													</div>

													<div className="mt-4">
														<PrTextInput
															label="Address Line 2"
															required={false}
															value={form.values?.address?.secondLine || ''}
															style={{ height: '2.375rem' }}
															onChange={(e) => {
																form.setFieldValue('address.secondLine', e.target.value || null);
															}}
														/>
													</div>

													<div className="mt-4 flex gap-4">
														<div className="w-full">
															<PrTextInput
																label="City *"
																value={form.values?.address?.city || ''}
																style={{ height: '2.375rem' }}
																onChange={(e) => {
																	form.setFieldValue('address.city', e.target.value || '');
																}}
															/>

															<ErrorMessage name="address.city" component="small" className="text-red font-medium" />
														</div>

														<div className="w-full">
															<PrTextInput
																label="Zip Code *"
																value={form.values?.address?.zip || ''}
																style={{ height: '2.375rem' }}
																onChange={(e) => {
																	form.setFieldValue('address.zip', e.target.value || '');
																}}
															/>

															<ErrorMessage name="address.zip" component="small" className="text-red font-medium" />
														</div>
													</div>
												</div>
											</div>
										</div>
									) : (
										<React.Fragment>
											<div className="mt-4">
												<h5 style={{ fontSize: '19px', fontWeight: '600' }}>
													Ship Labels <span className="pr-input-label-optional">(Optional)</span>
												</h5>

												<label htmlFor="printFile" className={styles.printFileWrapper}>
													<input
														type="file"
														value={''}
														onChange={(e) => {
															if (!e.target.files) return;

															const files = e.target.files as any as File[];

															const attachmentFiles = !!form.values.attachments ? [...form.values.attachments] : [];

															for (const file of files) {
																if (!file.name.endsWith('.pdf')) continue;
																if (attachmentFiles.findIndex((_attachmentFile) => _attachmentFile.name === file.name) > -1) continue;

																attachmentFiles.push(file);
															}

															form.setFieldValue('attachments', attachmentFiles);
														}}
														multiple
														accept=".pdf"
														name="printFile"
														id="printFile"
														className="absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer"
													/>

													<div role="button" tabIndex={0} className={styles.printFileBtn}>
														<FiPlus size="1.808rem" />
														<span>Ship Label File</span>
													</div>

													<div className={styles.printFileDivider} />

													<div className={styles.printFileInfo}>
														<span className={styles.printTitle}>Drag and Drop "Ship Labels" Here</span>
														<span className={styles.printAccepts}>You can upload: .pdf, Files</span>
													</div>
												</label>
											</div>

											{!!form.values.attachments ? (
												<div className="mt-4 flex flex-column gap-3">
													{form.values.attachments.map((_file, fileIndex) => (
														<div
															key={fileIndex}
															className={`flex justify-content-between h-3rem max-w-30rem border-1 cursor-pointer border-round-lg select-none${selectedAttachmentFile?.name === _file.name ? ' surface-100 border-600' : ' border-400'}`}
															onClick={() => {
																setSelectedAttachmentFile(_file.name === selectedAttachmentFile?.name ? undefined : _file);
															}}>
															<span className="h-full flex align-items-center px-3">{_file.name}</span>

															<span
																role="button"
																tabIndex={0}
																className="cursor-pointer px-3 border-left-1 h-full flex align-items-center border-400"
																onClick={(e) => {
																	e.stopPropagation();

																	setSelectedAttachmentFile(undefined);

																	const attachmentFiles = form.values.attachments?.filter((_attachmentFile) => _attachmentFile.name !== _file.name);

																	form.setFieldValue('attachments', !!attachmentFiles && attachmentFiles.length > 0 ? attachmentFiles : null);
																}}>
																<i className="pi pi-times"></i>
															</span>
														</div>
													))}
												</div>
											) : null}
										</React.Fragment>
									)}
									<div className="mt-3 flex align-items-center justify-content-end">
										<PrButton btnType="submit" text="Next" />
									</div>
								</Form>
							)}
						</Formik>
					</div>
				) : null}
			</section>

			<section className={`container-body ${styles.fourthStep} ${styles.stepWrapper}${step === 4 ? ` ${styles.active}` : step > 4 ? ` ${styles.completed}` : step < 4 ? ` ${styles.disabled}` : ''}`}>
				<h4>
					<span>4</span> Review
				</h4>

				{step === 4 ? (
					<div className="mt-4 mb-2">
						<div className="flex gap-3 align-items-start justify-content-between">
							<div className="w-full">
								<div className="flex gap-3 flex-wrap">
									<label className={`${styles.fileWrapper} tutorial-selector7`}>
										<input
											type="file"
											className={styles.fileInput}
											value=""
											multiple
											accept={acceptedFileExtensions.allAccepteds.join(', ')}
											onChange={(e) => {
												if (!e.target.files) return;

												const files: File[] = [];

												for (const file of e.target.files as any as File[]) {
													if (acceptedFileExtensions.allAccepteds.findIndex((_extension) => file.name.endsWith(_extension)) === -1) continue;

													if (!!reorderCompleteRequest.reasonMedias && reorderCompleteRequest.reasonMedias.findIndex((_media) => _media.name === file.name) > -1) continue;

													files.push(file);
												}

												const newRequest = new CreateReorderCompleteRequest({ ...reorderCompleteRequest });

												if (!newRequest.reasonMedias) newRequest.reasonMedias = files;
												else newRequest.reasonMedias = newRequest.reasonMedias.concat(files);

												setReorderCompleteRequest(newRequest);
											}}
										/>

										<GrAttachment size={30} />

										<span>Upload your reason</span>
									</label>

									{reorderCompleteRequest.reasonMedias?.map((_media, index) => (
										<div key={index} style={{ width: '4.125rem', height: '4.125rem', padding: '.2rem', border: '1px solid #D1D3D4', borderRadius: '.5rem', position: 'relative', userSelect: 'none' }}>
											<img src={URL.createObjectURL(_media)} className="block w-full h-full" style={{ objectFit: 'contain' }} />

											<span
												role="button"
												tabIndex={0}
												style={{
													width: '1rem',
													height: '1rem',
													borderRadius: '50%',
													position: 'absolute',
													display: 'flex',
													backgroundColor: '#D9D9D9',
													top: '-0.5rem',
													right: '-0.5rem',
													alignItems: 'center',
													justifyContent: 'center',
													cursor: 'pointer'
												}}
												onClick={() => {
													setReorderCompleteRequest(
														(_request) =>
															new CreateReorderCompleteRequest({
																..._request,
																reasonMedias: _request.reasonMedias?.filter((_currentMedia) => _currentMedia.name !== _media.name)
															})
													);
												}}>
												<IoMdClose size=".9rem" color="white" />
											</span>
										</div>
									))}
								</div>

								<small className="mt-3 block text-color-secondary">*Please attach the file that caused you to reorder (such as incorrect product, etc.).</small>
								<small className="mt-3 block text-color-secondary">{`${acceptedFileExtensions.allAccepteds.join(', ')} accepted`}</small>

								<div className="mt-4 w-6 tutorial-selector8">
									<PrTextareaInput
										label="Enter your note"
										rows={5}
										onChange={(event) => {
											setReorderCompleteRequest(new CreateReorderCompleteRequest({ ...reorderCompleteRequest, sellerNote: event.target.value || null }));
										}}
									/>
								</div>
							</div>
						</div>

						{orderDeliveryType === 1 ? order?.transport?.serviceName ? <div className="mt-4">Previously Selected Transport Service: {order.transport.serviceName}</div> : null : null}

						{orderDeliveryType === 1 ? (
							<div className="flex align-items-end justify-content-between gap-6">
								<div>
									<div className="flex gap-2 align-items-center mt-2 tutorial-selector9">
										<PrSwitchToggle checked={reorderCompleteRequest.isShippingServiceRequired} onChange={(e) => setReorderCompleteRequest(new CreateReorderCompleteRequest({ ...reorderCompleteRequest, isShippingServiceRequired: e }))} />

										<div className="cursor-pointer select-none" onClick={() => setReorderCompleteRequest(new CreateReorderCompleteRequest({ ...reorderCompleteRequest, isShippingServiceRequired: !reorderCompleteRequest.isShippingServiceRequired }))}>
											<span>Due to the delay/problem experienced, I would like my order to be shipped using a different shipping method.</span>
										</div>
									</div>

									<small className="mt-3 block text-color-secondary">*If your previous order was sent via express shipping, it will be sent via express shipping again. If your previous order was sent with a shipping method other than express shipping, the express shipping price may be reflected to you as a result of the evaluation by the printram team.</small>

									{reorderCompleteRequest.isShippingServiceRequired ? (
										<div className="h-15rem">
											{shippingRates && shippingRates.length > 0 ? <h5 className="mt-4">Shipping method</h5> : null}
											{shippingRates.map((_rate, index) => (
												<div key={index} className="field-radiobutton">
													<RadioButton
														inputId={`rate${_rate.rateId || _rate.serviceName}`}
														value={_rate.rateId || _rate.serviceName}
														name="rate"
														onChange={(event) => {
															setReorderCompleteRequest((_request) => {
																const newRequest = new CreateReorderCompleteRequest({ ..._request });

																for (const rates of shippingRates) {
																	if (rates.serviceName !== event.value && rates.rateId !== event.value) continue;

																	newRequest.requestShippingRate = rates;

																	break;
																}

																return newRequest;
															});
														}}
														checked={reorderCompleteRequest.requestShippingRate?.rateId ? _rate.rateId === reorderCompleteRequest.requestShippingRate?.rateId : _rate.serviceName === reorderCompleteRequest.requestShippingRate?.serviceName}
													/>

													<label htmlFor={`rate${_rate.rateId || _rate.serviceName}`}>
														{currencyHelper.formatPrice(+_rate.amount)} - {_rate.serviceName} {order?.transport?.serviceName && order.transport.serviceName === _rate.serviceName ? <span className="text-color-secondary">(Previously Selected)</span> : null}
													</label>
												</div>
											))}
										</div>
									) : null}
								</div>
							</div>
						) : null}

						<div className="flex justify-content-end">
							<PrButton
								text="Send to review"
								icon={<FaChevronRight />}
								onClick={async () => {
									try {
										setLoadingState({ isActive: true, text: 'Reorder Completing' });

										const response = await reorderService.complete(reorderCompleteRequest, (event) => {
											const percentage = Math.round((event.loaded * 100) / event.total);
											if (percentage !== 100) setLoadingState({ isActive: true, text: `File Processing: ${percentage}%` });
											else setLoadingState({ isActive: true, text: `Reorder Completing` });
										});

										if (!response.isSuccess) throw '';

										confirmDialog({
											showHeader: false,
											message: (
												<React.Fragment>
													<b className="mb-3 block" style={{ color: '#101531' }}>
														Your request has been received,
													</b>
													<p>After your request is reviewed by the Printram expert team, action will be taken regarding the order you have created. You can follow the request you created in the ticket section.</p>

													<div style={{ border: '1px solid #FCE7B8', backgroundColor: '#FEF8E9', padding: '1rem' }}>
														<p style={{ color: '#BD7417' }}>If the error that occurs after your request is reviewed by our team is caused by you or the shipping company.</p>

														<p style={{ color: '#BD7417' }}>Once your application has been reviewed, you will receive one or more of the following solutions;</p>

														<p className="m-0" style={{ color: '#BD7417' }}>
															{'1) You may be charged after the cargo product fee is submitted for your approval.'}
														</p>
														<p className="m-0" style={{ color: '#BD7417' }}>
															{'2) Will be money back in your balance account'}
														</p>
													</div>
												</React.Fragment>
											),
											contentClassName: 'm-0 pl-2 pt-4 pb-1',
											contentStyle: { borderTopLeftRadius: 4, borderTopRightRadius: 4 },
											closeOnEscape: false,
											className: 'm-0 p-0',
											style: { maxWidth: '48.375rem' },
											footer: (options: ConfirmDialogOptions) => (
												<div className="mb-2">
													<PrButton text="View request" btnType="button" onClick={() => options.accept()} />
												</div>
											),
											accept: () => navigate(`/ticket/${response.data?.ticketId}`)
										});
									} catch (error) {
									} finally {
										setLoadingState({ isActive: false, text: 'Reorder Completed' });
									}
								}}
							/>
						</div>
					</div>
				) : null}
			</section>

			{reorderDetail?.ticketId ? (
				<section className={`container-body ${styles.stepWrapper}`}>
					<div>Your reorder request is currently under evaluation. You can track the current status through this screen or the ticket screen.</div>
					<div>
						Ticket Code:{' '}
						<Link to={`/ticket/${reorderDetail?.ticketId}`} className="font-bold cursor-pointer text-black-alpha-90">
							{reorderDetail?.ticketCode} <i className="pi pi-external-link" />
						</Link>
					</div>
				</section>
			) : null}

			{!!selectedItem ? (
				<SelectPrintramProductDialogNew
					visible={selectedProductVisible}
					setVisible={setSelectedProductVisible}
					productId={selectedItem?.coreProductId}
					handleProductClick={(_product) => {
						if (!_product) return;

						setSelectedProduct(_product);
						// setSelectedItem(undefined);

						if (!sideWithDesignDialogVisible) setSideWithDesignDialogVisible(true);
					}}
				/>
			) : null}

			{!!selectedProduct ? (
				<SideWithDesignDialog
					product={selectedProduct}
					visible={sideWithDesignDialogVisible}
					setVisible={setSideWithDesignDialogVisible}
					color={selectedItem?.color}
					size={selectedItem?.size}
					editProduct={selectedConfirmSideWithDesign || undefined}
					onChangeProduct={() => {
						setSelectedProductVisible(true);
					}}
					onConfirm={(event) => {
						try {
							const clonedEditableItems: IterableItem[] = structuredClone(editableItems);

							for (const item of clonedEditableItems) {
								if (item.new.oldItemId !== selectedItem?.id) continue;

								item.new.confirmSideWithDesign = event;

								item.new.item = new CreateManuelOrderItemRequestDto({
									uniqueId: event.uniqueId,
									productId: event.product.id,
									productVariantId: event.variant.id,
									quantity: 1,
									sides: event.sides.map(
										(_side) =>
											new CreateManuelOrderItemSideRequestDto({
												productPrintSideId: _side.id,
												productPrintSideMethodId: _side.productPrintSideMethodId,
												designFile: _side.designFile,
												templateMockupFile: htmlToImageHelper.dataUrlToFile(_side.mockupBase64!, `${stringHelper.slugify(_side.name)}-mockup.png`),
												printWidth: Math.round((_side.printInfo?.printWidth || 0) * 10) / 10,
												printHeight: Math.round((_side.printInfo?.printHeight || 0) * 10) / 10,
												printMethodAttributes: _side.printMethodAttributes
											})
									)
								});

								break;
							}

							setEditableItems(clonedEditableItems);
							Cookies.get('re-order') !== 'true' && setTutorialSteps3Enabled(true);
						} catch (error) {
						} finally {
							setSelectedProduct(undefined);
							setSelectedItem(undefined);
						}
					}}
				/>
			) : null}

			{/* {!!selectedConfirmSideWithDesign ? (
				<SideWithDesignDialog
					visible={sideWithDesignDialogVisible}
					setVisible={setSideWithDesignDialogVisible}
					editProduct={selectedConfirmSideWithDesign}
					onConfirm={(event) => {
						try {
							const clonedEditableItems: IterableItem[] = structuredClone(editableItems);

							for (const item of clonedEditableItems) {
								if (item.new.oldItemId !== selectedItem?.id) continue;

								item.new.confirmSideWithDesign = event;

								item.new.item = new CreateManuelOrderItemRequestDto({
									uniqueId: event.uniqueId,
									productId: event.product.id,
									productVariantId: event.variant.id,
									quantity: 1,
									sides: event.sides.map(
										(_side) =>
											new CreateManuelOrderItemSideRequestDto({
												productPrintSideId: _side.id,
												designFile: _side.designFile,
												templateMockupFile: htmlToImageHelper.dataUrlToFile(_side.mockupBase64!, `${stringHelper.slugify(_side.name)}-mockup.png`),
												printWidth: _side.printInfo?.printWidth,
												printHeight: _side.printInfo?.printHeight
											})
									)
								});

								break;
							}

							setEditableItems(clonedEditableItems);
						} catch (error) {
						} finally {
							setSelectedConfirmSideWithDesign(undefined);
						}
					}}
				/>
			) : null} */}

			<PrLoading loading={loading.isActive} text={loading.text} />
		</main>
	);
};
