import HttpBaseService from '../../services/_common/http-base-service';
import { ShipmentLabelValidateRequest } from './shipment-label-validate/shipment-label-validate.request';
import { ShipmentLabelValidateResponse } from './shipment-label-validate/shipment-label-validate.response';

class ValidatorService extends HttpBaseService {
	constructor() {
		super('validator');
	}

	shipmentLabelValidate(request: ShipmentLabelValidateRequest): Promise<ShipmentLabelValidateResponse> {
		return this.postData(request.getConvertUriExtension, request, true);
	}
}

export const validatorService = new ValidatorService();
