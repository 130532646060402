import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import AppInlineMenu from './layout/AppInlineMenu';
import AppInlineHelpMenu from './layout/AppInlineHelpMenu';
import AppMenu from './layout/AppMenu';
import AppTopbar from './layout/AppTopbar';

import Payments from 'components/pages/account/components/payments/Payments';
import AssignedStore from 'components/pages/assignedStores/AssignedStore';
import BarcodesV2 from 'components/pages/barcodesV2/Barcodes';
import ManuelBarcodeV2 from 'components/pages/barcodesV2/pages/manuel-barcode/ManuelBarcode';
import OperationStepListV2 from 'components/pages/barcodesV2/pages/operation-step-list/OperationStepList';
import ReadBarcodeV2 from 'components/pages/barcodesV2/pages/read-barcode/ReadBarcode';
import GetShipLabelV2 from 'components/pages/barcodesV2/pages/get-ship-label/GetShipLabel';
import Barcodes from 'components/pages/barcodes/Barcodes';
import ManuelBarcode from 'components/pages/barcodes/pages/manuel-barcode/ManuelBarcode';
import OperationStepList from 'components/pages/barcodes/pages/operation-step-list/OperationStepList';
import ReadBarcode from 'components/pages/barcodes/pages/read-barcode/ReadBarcode';
import GetShipLabel from 'components/pages/barcodes/pages/get-ship-label/GetShipLabel';
import Contacts from 'components/pages/contacts/Contacts';
import EtsyConnections from 'components/pages/etsyConnections/EtsyConnections';
import BoostPotentialFormList from 'components/pages/forms/boost-potential-forms/BoostPotentialFormList';
import GetInTouchFormList from 'components/pages/forms/get-in-touch-forms/GetInTouchFormList';
import BarcodeScan from 'components/pages/orderManagement/barcodeScan/BarcodeScan';
import ExternalOrderDetail from 'components/pages/orders/ExternalOrderDetail';
import { CreateOrderContextProvider } from 'components/pages/orders/create-order/context/CreateOrderContextPrvider';
import PrintCenters from 'components/pages/printCenters/PrintCenters';
import AssignedProducts from 'components/pages/productManagement/assignedProducts/AssignedProducts';
import AddSide from 'components/pages/productManagement/products/sides/add-sides/AddSides';
import TemplateCategories from 'components/pages/productManagement/template-categories/TemplateCategories';
import TemplateProducts from 'components/pages/productManagement/template-producst/TemplateProducts';
import ProductCatalogCategories from 'components/pages/products/catalog/categories/ProductCatalogCategories';
import ProductDetailNew from 'components/pages/products/detail/ProductDetailNew';
import ProductImport from 'components/pages/products/merchant/import/ProductImport';
import SellerStores from 'components/pages/sellerStores/SellerStores';
import SellerMyPrices from 'components/pages/sellers/pages/my-prices/SellerMyPrices';
import SellerPrices from 'components/pages/sellers/pages/seller-prices/SellerPrices';
import PrintCenterStock from 'components/pages/stock/print-centers/PrintCenterStock';
import PrintCenterStockForAdmin from 'components/pages/stock/print-centers/PrintCenterStockForAdmin';
import StockManagement from 'components/pages/stock/print-centers/StockManagement';
import StockManagementForAdmin from 'components/pages/stock/StockManagement';
import PrintramStock from 'components/pages/stock/printrams/PrintramStock';
import SurveyList from 'components/pages/surveys/SurveyList';
import SurveyCreate from 'components/pages/surveys/components/SurveyCreate';
import SurveyUpdate from 'components/pages/surveys/components/SurveyUpdate';
import SellersAnswers from 'components/pages/surveys/components/sellers-answers/SellersAnswers';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Dashboard from './components/Dashboard';
import EmptyPage from './components/EmptyPage';
import AccountLayout from './components/pages/account/AccountLayout';
import MyAccount from './components/pages/account/components/myAccount/MyAccount';
import AccountPlans from './components/pages/account/components/plans/Plans';
import PrintOptions from './components/pages/account/components/printOptions/PrintOptions';
import Security from './components/pages/account/components/security/Security';
import Settings from './components/pages/account/components/settings/Settings';
import MyStores from './components/pages/account/components/stores/MyStores';
import Integrations from './components/pages/account/components/integrations/Integrations';
import Merchants from './components/pages/merchants/Merchants';
import OrderDetailForAdmin from './components/pages/orderManagement/orders/OrderDetail';
import AllOrders from './components/pages/orderManagement/orders/Orders';
import CreateShippingInformation from './components/pages/orderManagement/shippings/CreateShippingInformation';
import ShippingInformations from './components/pages/orderManagement/shippings/ShippingInformations';
import OrderDetail from './components/pages/orders/OrderDetail';
import Orders from './components/pages/orders/Orders';
import OrderDetailForPrintCenter from './components/pages/orders/printCenter/OrderDetail';
import OrdersForPrintCenter from './components/pages/orders/printCenter/Orders';
import Plans from './components/pages/plans/Plans';
import Brands from './components/pages/productManagement/brands/Brands';
import Categories from './components/pages/productManagement/categories/Categories';
import Colors from './components/pages/productManagement/colors/Colors';
import Models from './components/pages/productManagement/models/Models';
import Products from './components/pages/productManagement/products/Products';
import ProductDetailForAdmin from './components/pages/productManagement/products/components/ProductDetail';
import Sizes from './components/pages/productManagement/sizes/Sizes';
import ProductCatalogLayout from './components/pages/products/catalog/ProductCatalogLayout';
import ProductCreate from './components/pages/products/merchant/create/ProductCreate';
import ProductEdit from './components/pages/products/merchant/edit/ProductEdit';
import ProductGridList from './components/pages/products/merchant/gridList/ProductGridList';
import MyProducts from './components/pages/products/merchant/myProducts/MyProducts';
import EtsyUpload from './components/pages/products/seller/EtsyUpload';
import SellerProductDetail from './components/pages/products/seller/ProductDetail';
import SellerProducts from './components/pages/products/seller/ProductList';
import Resources from './components/pages/resources/Resources';
import ResourceDetail from './components/pages/resources/components/ResourceDetail';
import Sellers from './components/pages/sellers/Sellers';
import ResourcesForSeller from './components/pages/sellers/resources/Resources';
import TicketDetail from './components/pages/tickets/TicketDetail';
import Tickets from './components/pages/tickets/Tickets';
import menuHelper from './helpers/menu.helper';
import { useAppSelector } from './redux/hooks';
import { SyncOrderContextProvider } from 'components/pages/orders/sync-order/context/SyncOrderContextProvider';
import { browserName, isEdge, isIE as internetExplorer, isSafari } from 'react-device-detect';
import Dtfs from 'components/pages/dtfs/Dtfs';
import DtfDetail from 'components/pages/dtfs/DtfDetail';
import { ProductCatalogProducts } from 'components/pages/products/catalog/products/ProductCatalogProducts';
import { ReOrder } from 'components/pages/orders/re-order/ReOrder';
import { SellerReOrders } from 'components/pages/sellers/pages/re-orders/SellerReOrders';
import ReOrderTickets from 'components/pages/tickets/re-order/ReOrderTickets';
import { SellerReorderDetailContextProvider } from 'components/pages/sellers/pages/re-orders/detail/context/SellerReorderDetailContextProvider';
import Locations from 'components/pages/locations/Locations';
import { ReOrders } from 'components/pages/orderManagement/re-orders/ReOrders';
import { ReOrderDetail } from 'components/pages/tickets/re-order/detail/ReOrderDetail';
import ProductPrices from 'components/pages/productManagement/prices/ProductPrices';
import { CheckEtsyAuthenticationRequest } from 'features/integrations/_common/check-etsy-authentication/check-etsy-authentication.request';
import integrationsService from 'features/integrations/_common/integrations.service';
import { GoAlertFill } from 'react-icons/go';
import { EtsyAuthDto } from 'features/integrations/_common/check-etsy-authentication/dtos/etsy-auth.dto';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import toastHelper from 'helpers/toast.helper';
import WelcomePage from './components/pages/welcome/Welcome';
import PrintMethods from 'components/pages/productManagement/print-methods/PrintMethods';
import PrintMethodAttributes from 'components/pages/productManagement/print-methods/pages/PrintMethodAttributes';
import PrintCenterOperators from './components/pages/printCenterOperators/PrintCenterOperators';
import SellerGroups from 'components/pages/sellerGroups/SellerGroups';
import GroupPrices from 'components/pages/sellerGroups/pages/group-prices/GroupPrices';
import localStorageHelper from './helpers/local-storage.helper';
import CustomDtfWrapper from './components/pages/customDtf/components/CreateCustomDtfOrder/CreateCustomDtfOrder';
import CustomDtf from './components/pages/customDtf/CustomDtf';
import CustomDtfList from './components/pages/customDtf/components/CustomDtfList/CustomDtfList';
import CustomDtfListAdmin from './components/pages/customDtf/components/CustomDtfListAdmin/CustomDtfListAdmin';
import UpgradeShippingWrapper from './components/pages/upgradeShipping/UpgradeShipping';
import ShipLabelValidatorTestScreen from './components/pages/shipLabelValidatorTestScreen/shipLabelValidatorTestScreen';

export const RTLContext = React.createContext(false);

const App = () => {
	const user = useAppSelector((state) => state.auth.data?.user);
	const [menuMode, setMenuMode] = useState('static');
	const [inlineMenuPosition, setInlineMenuPosition] = useState('bottom');
	const [desktopMenuActive, setDesktopMenuActive] = useState(true);
	const [mobileMenuActive, setMobileMenuActive] = useState(false);
	const [activeTopbarItem, setActiveTopbarItem] = useState(null);
	const [colorMode, setColorMode] = useState('light');
	const [rightMenuActive, setRightMenuActive] = useState(false);
	const [menuActive, setMenuActive] = useState(false);
	const [inputStyle, setInputStyle] = useState('filled');
	const [isRTL, setRTL] = useState<boolean>(false);
	const [ripple, setRipple] = useState(true);
	const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
	const [menuTheme, setMenuTheme] = useState('light');
	const [topbarTheme, setTopbarTheme] = useState('blue');
	const [theme, setTheme] = useState('indigo');
	const [isInputBackgroundChanged, setIsInputBackgroundChanged] = useState(false);
	const [inlineMenuActive, setInlineMenuActive] = useState<any>({});
	const [searchActive, setSearchActive] = useState(false);
	const [etsyAuth, setEtsyAuth] = useState<EtsyAuthDto>();
	const [etsyAuthLoading, setEtsyAuthLoading] = useState<boolean>(false);
	let currentInlineMenuKey = useRef('');
	const copyTooltipRef = useRef<any>();
	const location = useLocation();

	/*const [phoneModalVisible, setPhoneModalVisible] = useState<boolean>(!user?.phoneNumbers || user.phoneNumbers.length === 0);*/

	PrimeReact.ripple = true;

	let searchClick: boolean;
	let topbarItemClick: boolean;
	let menuClick: boolean;
	let inlineMenuClick: boolean;

	const menu = menuHelper.getMenuByRoles(user);
	const navigate = useNavigate();

	const checkEtsyConnection = async (inUserControl?: boolean) => {
		try {
			setEtsyAuthLoading(true);

			const request = new CheckEtsyAuthenticationRequest();

			const response = await integrationsService.checkEtsyAuthentication(request);

			if (!response.isSuccess || !response.data) throw '';

			setEtsyAuth(response.data);

			if (response.data.isActive && inUserControl) toastHelper.success('Your etsy connection is active.');
		} catch (error) {
		} finally {
			setEtsyAuthLoading(false);
		}
	};

	useEffect(() => {
		const wizardAvaliable = localStorageHelper.get('wizardAvaliable');
		if (wizardAvaliable) navigate('on-boarding');
	}, []);

	useEffect(() => {
		copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
	}, [location]);

	useEffect(() => {
		if (menuMode === 'overlay') {
			hideOverlayMenu();
		}
		if (menuMode === 'static') {
			setDesktopMenuActive(true);
		}
	}, [menuMode]);

	useEffect(() => {
		if (user?.roles.isSeller) checkEtsyConnection();

		onColorModeChange(colorMode);
	}, []);

	useEffect(() => {
		const appLogoLink = document.getElementById('app-logo') as HTMLInputElement;

		if (topbarTheme === 'white' || topbarTheme === 'yellow' || topbarTheme === 'amber' || topbarTheme === 'orange' || topbarTheme === 'lime') {
			appLogoLink.src = 'assets/layout/images/logo-dark.svg';
		} else {
			appLogoLink.src = 'assets/layout/images/logo-light.svg';
		}
	}, [topbarTheme]);

	const onColorModeChange = (mode: string) => {
		setColorMode(mode);
		setIsInputBackgroundChanged(true);

		if (isInputBackgroundChanged) {
			if (mode === 'dark') {
				setInputStyle('filled');
			} else {
				setInputStyle('outlined');
			}
		}

		if (mode === 'dark') {
			setMenuTheme('dark');
			setTopbarTheme('dark');
		} else {
			setMenuTheme('light');
			setTopbarTheme('white');
		}

		const layoutLink = document.getElementById('layout-css');
		const layoutHref = 'assets/layout/css/layout-' + mode + '.css';
		replaceLink(layoutLink, layoutHref);

		const themeLink = document.getElementById('theme-css') as HTMLInputElement;
		const urlTokens = (themeLink.getAttribute('href') as String).split('/');
		urlTokens[urlTokens.length - 1] = 'theme-' + mode + '.css';
		const newURL = urlTokens.join('/');

		replaceLink(themeLink, newURL, () => {
			// setNewThemeLoaded(true);
		});
	};

	const replaceLink = (linkElement: any, href: string, callback?: any) => {
		if (isIE()) {
			linkElement.setAttribute('href', href);

			if (callback) {
				callback();
			}
		} else {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);

				if (callback) {
					callback();
				}
			});
		}
	};

	const onMenuClick = (event: any) => {
		menuClick = true;
	};

	const onMenuButtonClick = (event: Event) => {
		menuClick = true;

		if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
		else setMobileMenuActive((prevState) => !prevState);

		event.preventDefault();
	};

	const onTopbarItemClick = (event: any) => {
		topbarItemClick = true;
		if (activeTopbarItem === event.item) setActiveTopbarItem(null);
		else {
			setActiveTopbarItem(event.item);
		}

		event.originalEvent.preventDefault();
	};

	const onSearch = (event: any) => {
		searchClick = true;
		setSearchActive(event);
	};

	const onMenuItemClick = (event: any) => {
		if (!event.item.items && (menuMode === 'overlay' || !isDesktop())) {
			hideOverlayMenu();
		}

		if (!event.item.items && (isHorizontal() || isSlim())) {
			setMenuActive(false);
		}
	};

	const onRootMenuItemClick = (event: any) => {
		setMenuActive((prevState) => !prevState);
	};

	const onRightMenuButtonClick = () => {
		setRightMenuActive((prevState) => !prevState);
	};

	const onMobileTopbarButtonClick = (event: any) => {
		setMobileTopbarActive((prevState) => !prevState);
		event.preventDefault();
	};

	const onDocumentClick = (event: any) => {
		if (!searchClick && event.target.localName !== 'input') {
			setSearchActive(false);
		}

		if (!topbarItemClick) {
			setActiveTopbarItem(null);
		}

		if (!menuClick && (menuMode === 'overlay' || !isDesktop())) {
			if (isHorizontal() || isSlim()) {
				setMenuActive(false);
			}
			hideOverlayMenu();
		}

		if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
			let menuKeys = { ...inlineMenuActive };
			menuKeys[currentInlineMenuKey.current] = false;
			setInlineMenuActive(menuKeys);
		}

		if (!menuClick && (isSlim() || isHorizontal())) {
			setMenuActive(false);
		}

		searchClick = false;
		topbarItemClick = false;
		inlineMenuClick = false;
		menuClick = false;
	};

	const hideOverlayMenu = () => {
		setMobileMenuActive(false);
		setDesktopMenuActive(false);
	};

	const isDesktop = () => {
		return window.innerWidth > 1024;
	};

	const isHorizontal = () => {
		return menuMode === 'horizontal';
	};

	const isSlim = () => {
		return menuMode === 'slim';
	};

	const isIE = () => {
		return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
	};

	const onInlineMenuClick = (e: any, key: any) => {
		let menuKeys = { ...inlineMenuActive };
		if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
			menuKeys[currentInlineMenuKey.current] = false;
		}

		menuKeys[key] = !menuKeys[key];
		setInlineMenuActive(menuKeys);
		currentInlineMenuKey.current = key;
		inlineMenuClick = true;
	};

	const layoutContainerClassName = classNames('layout-wrapper ', 'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme, {
		'layout-menu-static': menuMode === 'static',
		'layout-menu-overlay': menuMode === 'overlay',
		'layout-menu-slim': menuMode === 'slim',
		'layout-menu-horizontal': menuMode === 'horizontal',
		'layout-menu-active': desktopMenuActive,
		'layout-menu-mobile-active': mobileMenuActive,
		'layout-topbar-mobile-active': mobileTopbarActive,
		'layout-rightmenu-active': rightMenuActive,
		'p-input-filled': inputStyle === 'filled',
		'p-ripple-disabled': !ripple,
		'layout-rtl': isRTL
	});

	return (
		<RTLContext.Provider value={isRTL}>
			<div className={layoutContainerClassName} onClick={onDocumentClick}>
				<Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

				<AppTopbar horizontal={isHorizontal()} activeTopbarItem={activeTopbarItem} menuKey="both" inlineMenuActive={inlineMenuActive} onMenuButtonClick={onMenuButtonClick} onInlineMenuClick={onInlineMenuClick} onTopbarItemClick={onTopbarItemClick} onRightMenuButtonClick={onRightMenuButtonClick} onMobileTopbarButtonClick={onMobileTopbarButtonClick} mobileTopbarActive={mobileTopbarActive} searchActive={searchActive} onSearch={onSearch} />

				<div className="menu-wrapper" onClick={onMenuClick}>
					<div className="layout-menu-container">
						{(inlineMenuPosition === 'top' || inlineMenuPosition === 'both') && <AppInlineMenu menuKey="top" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />}
						<AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} menuMode={menuMode} active={menuActive} />
						{(inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both') && <AppInlineHelpMenu menuKey="top" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />}
						{(inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both') && <AppInlineMenu menuKey="bottom" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />}
					</div>
				</div>

				<div className="layout-main">
					{isEdge || isSafari || internetExplorer ? (
						<div className="unsupported-browser">
							<h4>Unsupported Browser</h4>
							<p>
								{browserName} browser does not support some of the features we use in our system. For a better experience, you can use{' '}
								<b>
									<u>Chrome, Opera or Mozilla</u>
								</b>{' '}
								browsers.
							</p>
						</div>
					) : null}

					{etsyAuth ? (
						etsyAuth.isHaveConnection ? (
							etsyAuth.isActive === false ? (
								<div className="etsy-connection-lost">
									<GoAlertFill size={36} />
									<div>
										<h5>We couldn't authenticate your store, please reconnect your Etsy store.</h5>
										<p>Please go to “My Store” to reconnect your Etsy account with Printram or click to 'reconnect' button.</p>
										<p className="text-sm">This process may take a few seconds. You can use the 'check connection' button or refresh the page to check your connection.</p>
									</div>

									<Link to={etsyAuth.connectionUrl || ''} className="reconnect-btn">
										Reconnect
									</Link>

									<PrButton text="Check Connection" loading={etsyAuthLoading} onClick={() => checkEtsyConnection(true)} className="check-btn" />
								</div>
							) : null
						) : null
					) : null}

					<div className="layout-content">
						{user?.roles.isDtfManager ? (
							<Routes>
								<Route path="/">
									<Route path="" element={<Dtfs />} />
									<Route path=":dtfId" element={<DtfDetail />} />
								</Route>
							</Routes>
						) : !user?.roles.isPackager ? (
							<Routes>
								<Route path="/" element={user?.roles.isSeller ? <WelcomePage /> : <Dashboard />} />
								<Route path="empty" element={<EmptyPage />} />
								{user?.subscription?.planAndHelper?.helper.isHaveRecordedTraining ? (
									<>
										<Route path="resources">
											<Route path="" element={<ResourcesForSeller />} />
											<Route path=":resourceId" element={<ResourceDetail />} />
										</Route>
									</>
								) : null}

								{user?.roles.isPrintCenter ? <Route path="assigned-stores" element={<AssignedStore />} /> : null}

								{user?.roles.isSeller ? (
									<>
										<Route path="create-order" element={<CreateOrderContextProvider />} />
										<Route path="create-order/:coreProductId" element={<CreateOrderContextProvider />} />

										<Route path="sync-order/:orderId" element={<SyncOrderContextProvider />} />

										<Route path="re-order/:orderId" element={<ReOrder />} />

										<Route path="upgrade-shipping/:orderId" element={<UpgradeShippingWrapper />} />

										<Route path="custom-dtf" element={<CustomDtf />}>
											<Route path="" element={<CustomDtfWrapper />} />
											<Route path="orders" element={<CustomDtfList />} />
										</Route>

										<Route path="seller">
											<Route path="my-prices" element={<SellerMyPrices />} />
											<Route path="re-orders" element={<SellerReOrders />} />
											<Route path="re-orders/:reorderId/:newOrderId" element={<SellerReorderDetailContextProvider />} />
										</Route>
									</>
								) : null}

								<Route path="product">
									<Route path="catalog" element={<ProductCatalogLayout />}>
										<Route path="" element={<ProductCatalogCategories />} />
										<Route path=":categoryId" element={<ProductCatalogProducts />} />
									</Route>

									<Route path="detail/:productId" element={<ProductDetailNew />} />

									<Route path="merchant">
										{user?.roles.isMerchant && (
											<>
												<Route path="new" element={<ProductCreate />} />
												<Route path="import" element={<ProductImport />} />
												<Route path="new/:productId" element={<ProductCreate />} />
												<Route path="edit/:productId" element={<ProductEdit />} />
												<Route path="list" element={<MyProducts />} />
											</>
										)}

										<Route path=":categoryId" element={<ProductGridList />} />
									</Route>

									<Route path="seller">
										{user?.roles.isSeller && (
											<>
												<Route path="list" element={<SellerProducts />} />
												<Route path="detail/:productId" element={<SellerProductDetail />} />
												<Route path="etsy-upload/:productId" element={<EtsyUpload />} />
												<Route path="orders" element={<Orders />} />
												<Route path="order/:orderId" element={<OrderDetail />} />
												{/* <Route path="dtf-orders" element={<DtfOrders />} />
													<Route path="dtf-orders/create" element={<CreateDtfOrder />} />
													<Route path="dtf-orders/pay/:dtfOrderId" element={<PayDtfOrder />} /> */}
											</>
										)}
									</Route>

									<Route path="print-center">
										{user?.roles.isPrintCenter && (
											<>
												<Route path="orders" element={<OrdersForPrintCenter />} />
												<Route path="order/:orderId" element={<OrderDetailForPrintCenter />} />
												<Route path="stock-management" element={<StockManagement />} />
												<Route path="stocks" element={<PrintCenterStock />} />
											</>
										)}
									</Route>
								</Route>

								<Route path="account" element={<AccountLayout />}>
									{user?.roles.isSeller ? (
										<React.Fragment>
											<Route path="my-stores" element={<MyStores />} />
											<Route path="integrations" element={<Integrations />} />
											<Route path="plans" element={<AccountPlans />} />
											<Route path="payments" element={<Payments />} />
											<Route path="print-options" element={<PrintOptions />} />
											{/* <Route path="credit-cards" element={<PaymentMethods />} /> */}
										</React.Fragment>
									) : null}
									<Route path="security" element={<Security />} />
									<Route path="details" element={<MyAccount />} />
									<Route path="settings" element={<Settings />} />
								</Route>

								{user?.roles.isAdmin && (
									<>
										<Route path="surveys">
											<Route path="" element={<SurveyList />} />
											<Route path="create" element={<SurveyCreate />} />
											<Route path="update/:surveyId" element={<SurveyUpdate />} />
											<Route path="sellers-answers/:surveyName/:surveyId" element={<SellersAnswers />} />
										</Route>

										<Route path="test-label-validate" element={<ShipLabelValidatorTestScreen />} />

										<Route path="sellers" element={<Sellers />} />
										<Route path="seller-stores" element={<SellerStores />} />
										<Route path="etsy-connections" element={<EtsyConnections />} />
										<Route path="seller-prices/:sellerId" element={<SellerPrices />} />
										<Route path="seller-groups" element={<SellerGroups />} />
										<Route path="etsy-connections" element={<EtsyConnections />} />
										<Route path="seller-prices/:sellerId" element={<SellerPrices />} />
										<Route path="group-prices/:groupId" element={<GroupPrices />} />
										<Route path="merchants" element={<Merchants />} />
										<Route path="print-centers" element={<PrintCenters />} />
										<Route path="contacts" element={<Contacts />} />
										<Route path="products">
											<Route path="" element={<Products />} />
											<Route path=":id" element={<ProductDetailForAdmin />} />
											<Route path="sides">
												<Route path=":id" element={<AddSide />} />
											</Route>
										</Route>
										<Route path="product-prices" element={<ProductPrices />} />
										<Route path="brands" element={<Brands />} />
										<Route path="models" element={<Models />} />
										<Route path="categories" element={<Categories />} />
										<Route path="template-products" element={<TemplateProducts />} />
										<Route path="template-categories" element={<TemplateCategories />} />
										<Route path="colors" element={<Colors />} />
										<Route path="sizes" element={<Sizes />} />
										<Route path="assigned-products" element={<AssignedProducts />} />
										<Route path="print-methods">
											<Route path="" element={<PrintMethods />} />
											<Route path="attributes/:printMethodId" element={<PrintMethodAttributes />} />
										</Route>
										{/* <Route path="stock" element={<Stock />} /> */}
										<Route path="locations" element={<Locations />} />
										<Route path="orders" element={<AllOrders />} />
										<Route path="order/:orderId" element={<OrderDetailForAdmin />} />
										<Route path="upgrade-shipping/:orderId" element={<UpgradeShippingWrapper />} />
										<Route path="dtfs" element={<Dtfs />} />
										<Route path="dtfs/:dtfId" element={<DtfDetail />} />
										<Route path="barcode-scan" element={<BarcodeScan />} />

										<Route path="custom-dtf" element={<CustomDtf />}>
											<Route path="" element={<CustomDtfWrapper />} />
											<Route path="orders" element={<CustomDtfListAdmin />} />
										</Route>

										<Route path="operators" element={<PrintCenterOperators />} />

										<Route path="barcodes" element={<Barcodes />}>
											<Route path="" element={<ReadBarcode />} />
											<Route path="manuel" element={<ManuelBarcode />} />
											<Route path="operation-step-list" element={<OperationStepList />} />
											<Route path="get-ship-label" element={<GetShipLabel />} />
										</Route>

										<Route path="barcodes-beta" element={<BarcodesV2 />}>
											<Route path="" element={<ReadBarcodeV2 />} />
											<Route path="manuel" element={<ManuelBarcodeV2 />} />
											<Route path="operation-step-list" element={<OperationStepListV2 />} />
											<Route path="get-ship-label" element={<GetShipLabelV2 />} />
										</Route>

										<Route path="plans" element={<Plans />} />
										<Route path="stocks">
											<Route path="printram" element={<PrintramStock />} />
											<Route path="print-center" element={<PrintCenterStockForAdmin />} />
											<Route path="stock-management" element={<StockManagementForAdmin />} />
										</Route>
										<Route path="shipping-informations" element={<ShippingInformations />} />
										<Route path="shipping-informations/create" element={<CreateShippingInformation />} />
										<Route path="resources">
											<Route path="" element={<Resources />} />
											<Route path="detail/:resourceId">
												<Route path="" element={<ResourceDetail />} />
												<Route path=":videoId" element={<ResourceDetail />} />
											</Route>
										</Route>
										<Route path="forms">
											<Route path="get-in-touch-forms" element={<GetInTouchFormList />} />
											<Route path="boost-potential-forms" element={<BoostPotentialFormList />} />
										</Route>

										<Route path="re-orders">
											<Route path="" element={<ReOrders />} />
											<Route path=":id" element={<ReOrderDetail />} />
										</Route>

										<Route path="re-order-tickets">
											<Route path="" element={<ReOrderTickets />} />
											<Route path=":ticketId" element={<TicketDetail />} />
										</Route>
									</>
								)}

								{/* <Route path="my-tickets/:userId" element={<MyTickets />} /> */}
								<Route path="tickets" element={<Tickets />} />
								<Route path="ticket/:ticketId" element={<TicketDetail />} />
								<Route path="external-order/:orderId" element={<ExternalOrderDetail />} />
							</Routes>
						) : (
							/*							<Routes>
                                                            <Route path="/" element={<Barcodes />}>
                                                                <Route path="" element={<ReadBarcode />} />
                                                                <Route path="manuel" element={<ManuelBarcode />} />
                                                                <Route path="operation-step-list" element={<OperationStepList />} />
                                                                <Route path="get-ship-label" element={<GetShipLabel />} />
                                                            </Route>
                                                        </Routes>*/
							<Routes>
								<Route path="/" element={<Barcodes />}>
									<Route path="" element={<ReadBarcode />} />
									<Route path="manuel" element={<ManuelBarcode />} />
									<Route path="operation-step-list" element={<OperationStepList />} />
									<Route path="get-ship-label" element={<GetShipLabel />} />
								</Route>

								<Route path="beta" element={<BarcodesV2 />}>
									<Route path="" element={<ReadBarcodeV2 />} />
									<Route path="manuel" element={<ManuelBarcodeV2 />} />
									<Route path="operation-step-list" element={<OperationStepListV2 />} />
									<Route path="get-ship-label" element={<GetShipLabelV2 />} />
								</Route>
							</Routes>
						)}
					</div>
				</div>

				{mobileMenuActive && <div className="layout-mask modal-in"></div>}
			</div>
			<ConfirmDialog />

			{/*			{user?.roles.isSeller ?
				<PhoneAddressCompleteModal visible={phoneModalVisible} setVisible={setPhoneModalVisible}/> : null}*/}
		</RTLContext.Provider>
	);
};

export default App;
